<template>
  <div class="container-fluid p-0">
    <div class="page-headermb">
      <h2 class="main-content-title">Search Result
      </h2>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="search-result">
          <div></div>
          <div class="list-group">
            <table class="table">
              <thead class="thead-light">
                <tr>
                  <th scope="col">Type</th>
                  <th scope="col">Project Name
                  </th>
                  <th scope="col">Start Date</th>
                  <th scope="col">Deadline</th>
                  <!-- <th scope="col">Your Role</th> -->
                  <th scope="col">Collaborators
                  </th>
                  <th scope="col">Docs Collected
                  </th>
                  <th scope="col">Docs Approved
                  </th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="project in projects" :key="project.id">

                  <td v-if="project.project_type == 'PUBLIC'">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="21" viewBox="0 0 16 21">
                      <g id="lock-open" transform="translate(-4 -1)">
                        <rect id="Rectangle_2" data-name="Rectangle 2" width="14" height="10" rx="2"
                          transform="translate(5 11)" fill="none" stroke="#2f80ed" stroke-linecap="round"
                          stroke-linejoin="round" stroke-width="2" />
                        <circle id="Ellipse_13" data-name="Ellipse 13" cx="1" cy="1" r="1" transform="translate(11 15)"
                          fill="none" stroke="#2f80ed" stroke-linecap="round" stroke-linejoin="round"
                          stroke-width="2" />
                        <path id="Path_3" data-name="Path 3" d="M8,11V6a4,4,0,0,1,8,0" fill="none" stroke="#2f80ed"
                          stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                      </g>
                    </svg>
                  </td>
                  <td v-else>
                    <svg xmlns="http://www.w3.org/2000/svg" width="15.647" height="19" viewBox="0 0 15.647 19">
                      <path id="git-repository-private-line"
                        d="M5.608,9.143V18.19h11.3V9.143Zm10.431-1.81h1.739a.888.888,0,0,1,.869.9V19.1a.888.888,0,0,1-.869.9H3.869A.888.888,0,0,1,3,19.1V8.238a.888.888,0,0,1,.869-.9H5.608v-.9A5.325,5.325,0,0,1,10.824,1a5.325,5.325,0,0,1,5.216,5.429Zm-1.739,0v-.9A3.55,3.55,0,0,0,10.824,2.81,3.55,3.55,0,0,0,7.346,6.429v.9ZM6.477,10.048H8.216v1.81H6.477Zm0,2.714H8.216v1.81H6.477Zm0,2.714H8.216v1.81H6.477Z"
                        transform="translate(-3 -1)" fill="#2f80ed" />
                    </svg>
                  </td>
                  <td>{{ project.title }}</td>
                  <td>{{ project.start_date }}
                  </td>
                  <td>{{ project.end_date_first }}
                  </td>
                  <!-- <td>Host</td> -->
                  <td>{{ project.total_collaborators }}
                  </td>
                  <td>0</td>
                  <td>0</td>
                  <td><button style="border: none; background: none"
                      @click="gotoDetails(project.id, project.title, project.team_id, project.project_type)"
                      class="me-2">
                      <svg id="Group_48" data-name="Group 48" xmlns="http://www.w3.org/2000/svg" width="31" height="31"
                        viewBox="0 0 31 31">
                        <circle id="Ellipse_62" data-name="Ellipse 62" cx="15.5" cy="15.5" r="15.5" fill="#00b754"
                          opacity="0.1" />
                        <g id="Edit" transform="translate(10.075 10.075)">
                          <path id="Edit-2" data-name="Edit"
                            d="M6.8,10.851a.617.617,0,0,1,0-1.234h3.442a.617.617,0,0,1,0,1.234ZM.511,10.595l-.48-2.08A1.285,1.285,0,0,1,.275,7.432L4.029,2.573a.188.188,0,0,1,.256-.032L5.865,3.8a.509.509,0,0,0,.39.111.569.569,0,0,0,.492-.629.63.63,0,0,0-.2-.382L5.014,1.666a.228.228,0,0,1-.038-.317L5.571.577A1.557,1.557,0,0,1,7.854.421l.889.707a1.845,1.845,0,0,1,.69,1.057A1.446,1.446,0,0,1,9.14,3.416l-5.3,6.849a1.269,1.269,0,0,1-.985.492l-2.11.026A.241.241,0,0,1,.511,10.595Z"
                            fill="#00b754" />
                        </g>
                      </svg>
                    </button>

                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import FooterSection from "@/layouts/FooterSection";
import Sidebar from "@/layouts/Sidebar";
import Navbar from "@/layouts/Navbar";
import axios from "axios";
export default {
  name: "SearchResult",
  props: ['searchedData'],
  data() {
    return {
      projects: [],
    }
  },
  components: { FooterSection, Sidebar, Navbar },
  watch: {
    '$route.path': {
      handler(path) {

      },
      immediate: true,
      deep: true
    },
    '$route.query': {
      handler(query) {
        console.log('query', query)
        if(query.q){
          axios.get('/projects/search/'+query.q).then((res) => {
            this.projects = res.data.data;
          }).catch((error) => {
            console.log(error)
          })
        }
        else
        {
          this.projects = []
        }
      },
      immediate: true,
      deep: true
    }
  }, methods: {
    searchProducts() {

    },
    gotoDetails(id, title, team_id, project_type) {
      if (team_id == null && project_type == 'PUBLIC') {
        this.$router.push({ path: `personal-project/dashboard?menu=createPublicProject&active=primary_info&project_id=${id}&type=edit` });
      } else if ((team_id == null && project_type == 'PRIVATE')) {
        this.$router.push({ path: `personal-project/dashboard?menu=createPrivateProject&active=primary_info&project_id=${id}&type=edit` });
      } else if (team_id != null && project_type == 'PRIVATE') {
        this.$router.push({ path: `team/projects?team=${title}&id=${team_id}&menu=createPrivateProject&active=primary_info&project_id=${id}&type=edit` });
      } else if (team_id != null && project_type == 'PUBLIC') {
        this.$router.push({ path: `team/projects?team=${title}&id=${team_id}&menu=createPublicProject&active=primary_info&project_id=${id}&type=edit` });
      }
    },
  },

}
</script>

<style scoped>

</style>